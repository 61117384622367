import { request } from "@/request/http";

/**
 * @name: 作业详情页接口
 * @author: camellia
 * @date: 2022-02-28 
 */
export default {
    // 重置报告
    resetReport: (data) => {
        return request("POST", `/index.php/studentReport/resetReport`, data);
    },
    // 提交报告
    putReport: (data) => {
        return request("POST", `/index.php/studentReport/putReport`, data);
    },
    // 保存报告
    saveReport: (data) => {
        return request("POST", `/index.php/studentReport/saveReport`, data);
    },
    // 获取报告详情
    getReportDetail: (data) => {
        return request("POST", `/index.php/studentReport/getReportDetail`, data);
    },
    // 获取报告列表
    getReportList: (data) => {
        return request("POST", `/index.php/studentReport/getReportList`, data);
    },
};