<template>
    <!-- 素材列表页 -->
    <div class="reportDetail">
        <div class="div" >
            <div class="questionIndexBox">
                <div class="questionIndex">
                    <div class="questionTitle">题目</div>
                        <!-- <div class="scrollquestionIndex">
                            <div class="indexButton"  v-for="(item,index) in questionList" :key="index"><a :href="'#'+index">{{index+1}}</a></div>  
                        </div> -->
                        <el-button
                            class="scrollindexButton"
                            v-for="(item, index) in questionList"
                            :key="item.id"
                            @click="scroll(index)"
                            >{{ index + 1 }}
                        </el-button>
                </div>
                <div class="questionIndex" v-show = "remark">
                    <div class="questionTitle">退回原因</div>
                    <div class="questionTitleText">
                        {{remark}}
                    </div>
                </div>
            </div>

            <div v-if="rightContentShow" id="scrolldIV" class="edit_content">
                <!-- 未提交 -->
                <div v-if="report_status == 0">
                    <div class="report_file_url" v-if="report_file_url">
                        请先下载附件，根据附件中的实验数据完成实验报告。<font class="down_file" @click="downFile()">点击下载附件</font> 
                    </div>
                    <!-- 题目列表 -->
                    <div v-for="(item,index) in questionList" :key="index" class="report-item" name="item">
                        <!-- 索引 + title + 分数 -->
                        <div class="title-index">
                            <span class ="floatLeft span-index" >{{index + 1}}.</span>
                            <span class="questionScore" v-show="!item.must_answer">(非必答,{{item.score}}分)</span>
                            <span class="questionScore" v-show="item.must_answer">(必答,{{item.score}}分)</span>
                            <span v-html="item.title" class="span-title"></span>
                        </div>
                        <!-- ueditor编辑器 -->
                        <!-- 图片，文本 -->
                        <div v-if="item.type == 'image' || item.type == 'text'">
                            <vue-ueditor-wrap
                                v-model="item.value"
                                :config="myConfig"
                                @ready="ready"
                            ></vue-ueditor-wrap>
                        </div>
                        <!-- 虚拟 -->
                        <div v-if="item.type == 'xuni'" class="xuni">
                            <div class="sel" @click="openVirtualDialog(item.guanka,index,item.exp_id)">选择数据</div>
                            <div class="data">
                                <div v-for="(ite, inde) in item.value" :key="inde" >
                                    <span v-for="(it, ind) in ite" :key="ind">{{it}}</span>
                                </div>
                            </div>
                        </div>
                        <!-- 表格 -->
                        <div v-if="item.type == 'table'" class="tables">
                            <el-row v-for="(trItem, trIndex) in item.table" :key="trIndex">
                                <!-- @ 背景 #FFFFFF input -->
                                <!-- # 背景 #FAF8CF input -->
                                <!-- '' 背景 #D3D3D3 不可编辑 -->
                                <el-col :style="{ width: (100 / trItem.length) + '%' }" v-for="(tdItem, tdIndex) in trItem" :key="tdIndex">
                                    <div v-if="tdItem && tdItem.substring(0, 1) == '@'">
                                        <el-input v-model="item.value[trIndex][tdIndex]" :value="item.value[trIndex][tdIndex]" placeholder="请输入内容" ></el-input>
                                    </div>
                                    <div v-else-if="tdItem && tdItem.substring(0, 1) == '#'" class="yellow">
                                        <el-input v-model="item.value[trIndex][tdIndex]" :value="item.value[trIndex][tdIndex]" placeholder="请输入内容" ></el-input>
                                    </div>
                                    <div v-else>
                                        <div v-if="tdItem">
                                            <el-input v-model="item.table[trIndex][tdIndex]" disabled="disabled"></el-input>
                                        </div>
                                        <div v-else>
                                            <el-input disabled="disabled"></el-input>
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                    </div>
                </div>
                <!-- 已提交  未评阅 -->
                <div v-if="report_status == 1">
                    <div v-if="report_file_url">
                        <br>
                        请先下载附件，根据附件中的实验数据完成实验报告。<span class="down_file" @click="downFile()">点击下载附件</span> 
                    </div>
                    <!-- 题目列表 -->
                    <div v-for="(item,index) in questionList" :key="index" class="report-item" :id="index" name="item">
                        <!-- 索引 + title + 分数 -->
                        <div class="title-index">
                            <span class ="floatLeft span-index" >{{index + 1}}.</span>
                            <span class="questionScore" v-show="!item.must_answer">(非必答,{{item.score}}分)</span>
                            <span class="questionScore" v-show="item.must_answer">(必答,{{item.score}}分)</span>
                            <span v-html="item.title" class="span-title"></span>
                        </div>
                        <!-- ueditor编辑器 -->
                        <!-- 图片，文本 -->
                        <div v-if="item.type == 'image' || item.type == 'text'" v-html="item.value"></div>
                        <!-- 虚拟 -->
                        <div v-if="item.type == 'xuni'" class="xuni">
                            <div class="data">
                                <div v-for="(ite, inde) in item.value" :key="inde" >
                                    <span v-for="(it, ind) in ite" :key="ind">{{it}}</span>
                                </div>
                            </div>
                        </div>
                        <!-- 表格 -->
                        <div v-if="item.type == 'table'" class="tables">
                            <el-row v-for="(trItem, trIndex) in item.table" :key="trIndex">
                                <!-- @ 背景 #FFFFFF input -->
                                <!-- # 背景 #FAF8CF input -->
                                <!-- '' 背景 #D3D3D3 不可编辑 -->
                                <el-col :style="{ width: (100 / trItem.length) + '%' }" v-for="(tdItem, tdIndex) in trItem" :key="tdIndex">
                                    <div v-if="tdItem && tdItem.substring(0, 1) == '@'">
                                        <el-input v-model="item.value[trIndex][tdIndex]" :value="item.value[trIndex][tdIndex]" disabled="disabled" class="disabled"></el-input>
                                    </div>
                                    <div v-else-if="tdItem && tdItem.substring(0, 1) == '#'" class="yellow">
                                        <el-input v-model="item.value[trIndex][tdIndex]" :value="item.value[trIndex][tdIndex]" disabled="disabled" class="disabled"></el-input>
                                    </div>
                                    <div v-else>
                                        <div v-if="tdItem">
                                            <el-input v-model="item.table[trIndex][tdIndex]" disabled="disabled"></el-input>
                                        </div>
                                        <div v-else>
                                            <el-input disabled="disabled"></el-input>
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                    </div>
                </div>
                <!-- 已评阅 -->
                <div v-if="report_status == 2">
                    <div v-if="report_file_url">
                        <br>
                        请先下载附件，根据附件中的实验数据完成实验报告。<span class="down_file" @click="downFile()">点击下载附件</span> 
                    </div>
                    <!-- 题目列表 -->
                    <div v-for="(item,index) in questionList" :key="index" class="report-item" :id="index" name="item">
                        <!-- 索引 + title + 分数 -->
                        <div class="title-index">
                            <span class ="floatLeft span-index" >{{index + 1}}.</span>
                            <span class="questionScore" v-show="!item.must_answer">(非必答,{{item.score}}分)</span>
                            <span class="questionScore" v-show="item.must_answer">(必答,{{item.score}}分)</span>
                            <span v-html="item.title" class="span-title"></span>
                        </div>
                        <!-- ueditor编辑器 -->
                        <!-- 图片，文本 -->
                        <div v-if="item.type == 'image' || item.type == 'text'" v-html="item.value"></div>
                        <!-- 虚拟 -->
                        <div v-if="item.type == 'xuni'">

                        </div>
                        <!-- 表格 -->
                        <div v-if="item.type == 'table'" class="tables disabled">
                            <el-row v-for="(trItem, trIndex) in item.table" :key="trIndex">
                                <!-- @ 背景 #FFFFFF input -->
                                <!-- # 背景 #FAF8CF input -->
                                <!-- '' 背景 #D3D3D3 不可编辑 -->
                                <el-col :style="{ width: (100 / trItem.length) + '%' }" v-for="(tdItem, tdIndex) in trItem" :key="tdIndex">
                                    <div v-if="tdItem && tdItem.substring(0, 1) == '@'">
                                        <el-input v-model="item.value[trIndex][tdIndex]" :value="item.value[trIndex][tdIndex]" disabled="disabled" class="disabled"></el-input>
                                    </div>
                                    <div v-else-if="tdItem && tdItem.substring(0, 1) == '#'" class="yellow">
                                        <el-input v-model="item.value[trIndex][tdIndex]" :value="item.value[trIndex][tdIndex]" disabled="disabled" class="disabled"></el-input>
                                    </div>
                                    <div v-else>
                                        <div v-if="tdItem">
                                            <el-input v-model="item.table[trIndex][tdIndex]" disabled="disabled"></el-input>
                                        </div>
                                        <div v-else>
                                            <el-input disabled="disabled"></el-input>
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                        <br>
                        <hr >
                        <!-- 批注（涂鸦） -->
                        <div class="tuya-title" v-if="item.tuya">教师批注：</div>
                        <div v-if="item.tuya" v-html="item.tuya"></div>
                        <!-- 标注 -->
                        <div class="bz-title" v-if="item.channelstr.length > 0">教师标注：</div>
                        <div v-if="item.channelstr.length > 0">
                            <span v-for="(it,ind) in item.channelstr" :key="ind" class="bz-item">{{it}}</span>
                        </div>
                    </div>
                </div>
                <div class="backFix">
            <el-button class="themecancelButton" @click="goBack()">返回</el-button>
            <!-- <el-button type="primary" @click="execUpdateArticle()"
                >提&nbsp;&nbsp;交</el-button
            > -->
        </div>
        <div class="saveFix" >
            <div class="saveBox">
                <div class="floatRight" v-if="report_status == 0">
                    <el-button class="resetButton" @click="resetReport()">重置</el-button>
                    <el-button class="themecancelButton" @click="saveReport()">保存</el-button>
                    <el-button class="themeButton" @click="putReport()"> 提交</el-button>
                </div>
            </div>
        </div>
            </div>
            <div v-else>
                <div class="recover-button">
                    <h3>暂无数据~</h3><br>
                </div>
            </div>
        </div>
        <!-- 重置/提交报告弹窗 -->
        <el-dialog
            :title="dialogTitle"
            :visible.sync="dialogShow"
            width="30%">
            <span v-if="dialogSign == 'reset'">
                报告重置后，已提交的所有信息都会被清空！
            </span>
            <span v-if="dialogSign == 'put'">
                确定要提交报告吗？ <br>
                报告提交后不可修改！！！
            </span>
            <span v-if="dialogSign == 'update'">
                报告模板已被老师修改，是否重置报告？
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button class="themecancelButton" @click="dialogShow = false">取 消</el-button>
                <el-button class="themeButton" @click="resetReportExec()">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog
            title="虚拟实验数据选择"
            :visible.sync="dialogVirtualShow"
            width="30%" >
            <span class="dialogbody">
                <span class="title">
                    <span class="radio"></span>
                    <span class="cid">关卡</span>
                    <span class="time">时间</span>
                </span>
                <span class="title body-title" v-for="(item, index) in virtualDataList" :key="index" >
                    <span class="radio">
                        <el-checkbox v-model="item.check" @change="checkBoxChange()"></el-checkbox>
                    </span>
                    <span class="cid">{{item.CID}}</span>
                    <span class="time">{{item.subtime}}</span>
                </span>
            </span>
            <span slot="footer" class="dialog-footer">
                <el-button class="themecancelButton" @click="dialogVirtualShow = false">取 消</el-button>
                <!-- {"project_id":"16838","expdata_id":7369352} -->
                <el-button class="themeButton" @click="getExpDataInfoDataById()">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<style>
</style>
<style lang="scss" scoped>
    @import "@/assets/css/student/report/reportDetail.scss";
</style>

<script>
import reportDetail from "@/assets/js/student/report/reportDetail.js";
export default {
    ...reportDetail
};
</script>
