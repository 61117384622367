import { Message } from "element-ui"; //项目已经全局引入element的话可以不单独引入
import { startLoading, endLoading, sleep } from "@/utils/function";
import { 
    domainUrl, 
    courseSorcePageSize,
} from "@/utils/const";
import VueUeditorWrap from "vue-ueditor-wrap";
import { editorUploadUrl } from "@/utils/const";
import API from "@/api/student/report/reportDetail";
import { nprogressStart, nprogressClose } from "@/plugins/nprogress.js";
import { request } from "@/request/http";
export default {
    name: "other",
    // 页面组件
    components: {
        VueUeditorWrap,
    },
    inject:['reload'],
    data() {
        return {
            // ueditor 编辑器配置项
            myConfig: {
                // 初始容器宽度
                initialFrameWidth: "100%",
                // 初始容器高度
                initialFrameHeight: 100,
                // 你的UEditor资源存放的路径,相对于打包后的index.html
                UEDITOR_HOME_URL: "./Editor/",
                // 编辑器不自动被内容撑高
                // autoHeightEnabled: false,
                // 如果需要上传功能,找后端小伙伴要服务器接口地址
                serverUrl: editorUploadUrl,
                // 关闭自动保存
                enableAutoSave: false,
                // 自定义工具栏，需要额外选项可以参考ueditor.config.js
                toolbars: [
                [
                    "inserttable",//表格
                    "source", //源代码
                    "bold", //加粗
                    "italic", //斜体
                    "underline", //下划线
                    "strikethrough", //删除线
                    "fontborder", //字符边框
                    "blockquote", //引用
                    "selectall", //全选
                    "horizontal", //分隔线
                    "removeformat", //清除格式
                    "unlink", //取消链接
                    "deletecaption", //删除表格标题
                    "inserttitle", //插入标题
                    "cleardoc", //清空文档
                    "insertcode", //代码语言
                    "fontfamily", //字体
                    "fontsize", //字号
                    "insertimage", //多图上传
                    "link", //超链接
                    "emotion", //表情
                    "spechars", //特殊字符
                    "searchreplace", //查询替换
                    "insertvideo", //视频
                    "justifyleft", //居左对齐
                    "justifyright", //居右对齐
                    "justifycenter", //居中对齐
                    "forecolor", //字体颜色
                    "insertorderedlist", //有序列表
                    "insertunorderedlist", //无序列表
                    "imageleft", //左浮动
                    "imageright", //右浮动
                    "attachment", //附件
                    "imagecenter", //居中
                    "lineheight", //行间距
                ],
                ],
            },
            deleteCourseShowSign: false,
            activeName: "first",
            reportList: [],
            currentCourse_id: 0,
            project_id: "",
            rightContentShow: true,
            update_status: 0,
            virtual_data: [],
            questionList: [],
            dialogShow: false,
            dialogTitle: "",
            dialogSign: "",
            user_id: "",
            report_status:0,
            report_file_url:'',
            remark:'',
            dialogVirtualShow: false,
            virtualIndex:0,
            exp_id:"",
            virtualDataList:[],
            expdata_id:"",
            virtualList:[],
            column:0,
        };
    },
    /**
     * @name: 页面创建成功之后
     */
    created() {
        const self = this;
        self.getReportDetail();
    },
    /**
     * @name: 挂载成功之后
    */
    mounted() {
        const self = this;
        if (self.reportList.length > 0) {
        sleep(800).then(() => {
            self.updateCssStyle(self.reportList[0].index);
        });
        }
    },
    methods: {
        getExpDataInfoDataById()
        {
            let self = this;
            // 请求参数
            let data = {
                project_id: self.project_id,
                expdata_id: self.expdata_id,
            };
            nprogressStart();
            self.$Loading.show();
            request("POST", `/index.php/studentReport/getExpDataInfoDataById`, data)
                .then((result) => {
                    nprogressClose();
                    self.$Loading.hide();
                    console.log(result)
                    self.dialogVirtualShow = false;
                    if(result.code > 0)
                    {
                        self.questionList[self.virtualIndex].value = result.data;
                        self.questionList[self.virtualIndex].column = result.column;
                        self.questionList[self.virtualIndex].expdata_id = result.expdata_id;
                        // self.virtualList = result.data;
                        // self.column = result.column;
                    }
                    else
                    {
                        return self.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType:'warning',
                            displayTime:1500
                        });
                    }
                    console.log(self.questionList)
                }) 
        },
        checkBoxChange()
        {
            let self = this;
            if(self.expdata_id != "")
            {
                self.virtualDataList.forEach((item,index)=>{
                    if(self.expdata_id == item.id)
                    {
                        item.check = !item.check;
                    }
                })
            }
            self.virtualDataList.forEach((item,index)=>{
                if(item.check)
                {
                    self.expdata_id = item.id;
                }
            })
        },
        openVirtualDialog(cid,index,exp_id)
        {
            let self = this;
            self.virtualList = [],
            self.virtualIndex = index;
            self.exp_id = exp_id;
            // 请求参数
            let data = {
                project_id: self.project_id,
                cid: cid,
                exp_id: self.exp_id,
                expdata_id:self.expdata_id
            };
            nprogressStart();
            self.$Loading.show();
            request("POST", `/index.php/studentReport/getExpDataByProjectAndCid`, data)
                .then((result) => {
                    // console.log(result)
                    nprogressClose();
                    self.$Loading.hide();
                    if(result.code < 0)
                    {
                        return self.$Tips({
                            // 消息提示内容
                            message: result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType:'warning',
                            displayTime:1500
                        });
                    }
                    self.dialogVirtualShow = true;
                    self.virtualDataList = result.data;
                })  
        },
        /**
         * @name: 滑动窗口到指定dom位置
         */
        scroll(index) 
        {
            document
                .getElementsByName("item")
                [index].scrollIntoView({ behavior: "smooth" });
        },
        /**
         * @name: 返回上一级
         */
        goBack() 
        {
            // this.$router.back()
            let self = this;
            self.$router.push({
                path: "/student/reportList",
                query: {
                    // article_id: self.article_id,
                    // name:self.categoryName,
                    hideMenu:0
                }
            }).then((res)=>{
                self.reload()
                // console.log(res);
            })                  
        },
        downFile()
        {
            window.open(this.report_file_url);
        },
        resetReport()
        {
            var self = this;
            self.dialogShow = true;
            self.dialogSign = "reset";
            self.dialogTitle = "重置报告";
        },
        resetReportExec()
        {
            var self = this;
            let data = {
                project_id: self.project_id,
            };
            API.resetReport(data)
                .then((result)=>{

                    if (result.code > 0) 
                    {
                        // Message.success(result.msg);
                        self.dialogShow = false;
                        self.getReportDetail(self.project_id, self.courseName);
                        self.$Tips({
                            // 消息提示内容
                            message:result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType:'success',
                            displayTime:1500
                        })
                    }
                    else
                    {
                        self.$Tips({
                            // 消息提示内容
                            message:result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType:'warning',
                            displayTime:1500
                        })
                    }
                })
                .catch((err) => {

                });
        },
        putReport()
        {
            var self = this;
            var record = [];
            for (var i = 0; i < self.questionList.length; i++) 
            {
                if ((self.questionList[i].type == 'image' || self.questionList[i].type == 'text') && self.questionList[i].must_answer == true)
                {
                    if(!self.questionList[i].value)
                    {
                        // Message.warning("请填写完整报告！");return;
                        return self.$Tips({
                            // 消息提示内容
                            message:'请填写完整报告',
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType:'warning',
                            displayTime:1500
                        })
                    }
                }
                else if(self.questionList[i].type == 'table' && self.questionList[i].must_answer == true)
                {
                    // for (let ii = 0; ii < self.questionList[i].value.length; ii++) 
                    // {
                    //     for (let iii = 0; iii < self.questionList[i].value[ii].length; iii++) 
                    //     {
                    //         if(!self.questionList[i].value[ii][iii])
                    //         {
                    //             Message.warning("请填写完整报告！");return;
                    //         }
                    //     }
                    // }
                    // ==================================================================================
                    // ADD BY LXY
                    let arr = [];
                    for (let ii = 0; ii < self.questionList[i].value.length; ii++) 
                    {
                        arr.push(...(self.questionList[i].value[ii].splice(1)))
                    }
                    let empity = arr.every((item)=>{
                        item === ""
                    })
                    if(empity)
                    {
                        // Message.warning("请填写完整报告！");return;
                        return self.$Tips({
                            // 消息提示内容
                            message:'请填写完整报告',
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType:'warning',
                            displayTime:1500
                        })
                    }
                    // ==================================================================================
                } 
                record.push(JSON.stringify(self.questionList[i]));
            }
            // 开启loading
            nprogressStart();
            self.$Loading.show();
            let data = {
                project_id: self.project_id,
                record: "[" + record.toString() + "]",
            };
            API.putReport(data)
                .then((result)=>{
                    if (result.code > 0) 
                    {
                        self.getReportDetail(self.project_id, self.courseName);
                        // Message.success(result.msg);
                        self.$Tips({
                            // 消息提示内容
                            message:result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType:'success',
                            displayTime:1500
                        })
                    }
                    else
                    {
                        self.$Tips({
                            // 消息提示内容
                            message:result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType:'warning',
                            button:[
                                {
                                    type:1,
                                    text:'知道了'
                                }
                            ],
                        })
                    }
                    // 关闭loading
                    nprogressClose();
                    self.$Loading.hide();
                })
                .catch((err) => {
                    // 关闭loading
                    nprogressClose();
                    self.$Loading.hide();
                });
        },
        saveReport()
        {
            var self = this;
            // 开启loading
            nprogressStart();
            self.$Loading.show();
            var record = [];
            for(var i = 0;i < self.questionList.length;i++)
            {
                record.push(JSON.stringify(self.questionList[i]));
            }
            let data = {
                project_id: self.project_id,
                record: '['+record.toString()+']',
            };
            API.saveReport(data)
                .then((result)=>{
                    if (result.code > 0) 
                    {
                        // Message.success(result.msg);
                        self.$Tips({
                            // 消息提示内容
                            message:result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType:'success',
                            displayTime:1500
                        })
                    }
                    else
                    {
                        self.$Tips({
                            // 消息提示内容
                            message:result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType:'warning',
                            displayTime:1500
                        })
                    }
                    // 关闭loading
                    nprogressClose();
                    self.$Loading.hide();
                })
                .catch((err) => {
                    // 关闭loading
                    nprogressClose();
                    self.$Loading.hide();
                });
        },
        ready(editorInstance) 
        {
            var self = this;
            editorInstance.focus();
            // 这是oss目录名称
            var str = "report_" + self.project_id + "/" + self.user_id;
            // 添加自定义参数
            editorInstance.execCommand("serverparam", function(editor) {
                return {
                articleid: str, //设置富文本编辑器请求时，携带的额外参数
                };
            });
        },
        handleAvatarSuccess(res) 
        {
            const self = this;
            // console.log(res);
            if (res.code > 0) 
            {
                self.courseInfo.pic = res.data;
                // Message.success(res.msg);
                self.$Tips({
                    // 消息提示内容
                    message:res.msg,
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType:'success',
                    displayTime:1500
                })
            } 
            else 
            {
                // Message.error(res.msg);
                self.$Tips({
                    // 消息提示内容
                    message:res.msg,
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType:'warning',
                    displayTime:1500
                })
            }
        },

        beforeAvatarUpload(file) 
        {
            let self = this;
            const isJPG =
                file.type === "image/jpeg" ||
                "image/png" ||
                "image/jpg" ||
                "image/gif" ||
                "image/bmp";
            const isLt2M = file.size / 1024 / 1024 < 5;
            if (!isJPG) 
            {
                // Message.success("上传头像图片只能是 JPG/png/gif/bmp 格式!");
                self.$Tips({
                    // 消息提示内容
                    message:"上传头像图片只能是 JPG/png/gif/bmp 格式",
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType:'warning',
                    displayTime:1500
                })
            }
            if (!isLt2M) 
            {
                // Message.error("上传头像图片大小不能超过 5MB!");
                self.$Tips({
                    // 消息提示内容
                    message:"上传头像图片大小不能超过 5MB",
                    // 消息提示类型（success-成功,warning-警告/失败）
                    messageType:'warning',
                    displayTime:1500
                })
            }
            return isJPG && isLt2M;
        },
        getReportDetail(id = "", name = "", sign = "") 
        {
            const self = this;
            // 开启loading
            nprogressStart();
            self.$Loading.show();
            self.project_id = self.$route.query.project_id;
            self.rightContentShow = true;
            if (sign) {
                self.courseName = name;
                self.rightContentShow = false;
            }
            let data = {
                project_id: self.project_id,
            };
            API.getReportDetail(data)
                .then((result)=>{
                    if (result.code > 0) 
                    {
                        self.update_status = result.data.update_status;
                        self.remark = result.data.remark;
                        self.questionList = result.data.content;
                        self.virtual_data = result.data.virtual_data;
                        self.user_id = result.data.user_id;
                        self.report_status = result.data.report_status;
                        // 报告模板附件
                        self.report_file_url = result.report_file_url;
                        if (self.update_status == 1) 
                        {
                            self.dialogShow = true;
                            self.dialogSign = "update";
                            self.dialogTitle = "重置报告";
                        }
                        // 滚动条移动到最下
                        sleep(500).then(() => {
                            // 这里写sleep之后需要去做的事情
                            document.getElementById("scrolldIV").scrollIntoView(true); 
                        });
                    }
                    // 关闭loading
                    nprogressClose();
                    self.$Loading.hide();
                })
                .catch((err) => {
                    // 关闭loading
                    nprogressClose();
                    self.$Loading.hide();
                });
        },

        getReportList() 
        {
            const self = this;
            nprogressStart();
            API.getReportList(data)
                .then((result)=>{
                    if (result.code == 1) 
                    {
                        self.reportList = result.data;
                    } 
                    else if (result.code == 10) 
                    {
                        // Message.warning(result.msg);
                        self.$Tips({
                            // 消息提示内容
                            message:result.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType:'warning',
                            displayTime:1500
                        })
                        return;
                    }
                    // 关闭loading
                    nprogressClose();
                    self.$Loading.hide();
                })
                .catch((err) => {
                    // 关闭loading
                    nprogressClose();
                    self.$Loading.hide();
                });
        },
        updateCssStyle(id) 
        {
            // css效果
            var d = document.querySelectorAll(".left-module tr");
            for (var p = d.length; p--; ) {
                if (d[p].id != id) {
                d[p].style.backgroundColor = "#FFFFFF"; /*其他*/
                } else {
                d[p].style.backgroundColor = "#F5F6F7"; /*点击的*/
                }
            }
        },
        tabHandleClick(tab, event) {},
    },
};
